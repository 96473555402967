import request from "../utils/request";

export default {
  getWebsiteSet() {
    return request({
      url: "/set/website",
      method: "get",
    });
  },
  updateWebsiteSet(data) {
    return request({
      url: "/set/website",
      method: "post",
      data: data,
    });
  },
  getNav() {
    return request({
      url: "/set/nav",
      method: "get",
    });
  },
  getLink() {
    return request({
      url: "/set/link",
      method: "get",
    });
  },
  updateNav(data) {
    return request({
      url: "/set/nav",
      method: "post",
      data: data,
    });
  },
  updateLink(data) {
    return request({
      url: "/set/link",
      method: "post",
      data: data,
    });
  },
  deleteLink(id) {
    return request({
      url: "/set/link",
      method: "delete",
      data: { id: id },
    });
  },
};
