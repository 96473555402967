<template>
  <a-modal
    v-model:visible="addModalStatus"
    title="添加友情链接"
    width="888px"
    cancelText="取消"
    okText="提交"
    @ok="handleOk"
  >
    <a-form
      :model="addData"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-item label="标题" name="title">
        <a-input v-model:value="addData.title" style="width: 400px" />
      </a-form-item>

      <a-form-item label="跳转链接" name="url">
        <a-input v-model:value="addData.url" style="width: 400px" />
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal
    v-model:visible="updateModalStatus"
    title="修改友情链接"
    width="888px"
    cancelText="取消"
    okText="更新"
    @ok="handleUpdateOk"
  >
    <input type="hidden" name="id" :value="updateData.id" />
    <a-form
      :model="updateData"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-item label="标题" name="title">
        <a-input v-model:value="updateData.title" style="width: 400px" />
      </a-form-item>

      <a-form-item label="跳转链接" name="url">
        <a-input v-model:value="updateData.url" style="width: 400px" />
      </a-form-item>
    </a-form>
  </a-modal>

  <div style="background-color: #f0f2f5">
    <div
      style="
        width: 100%;
        height: 64px;
        margin-bottom: 10px;
        background-color: #ffffff;
        line-height: 64px;
      "
    >
      <div
        style="
          width: 120px;
          line-height: 64px;
          margin: 0 20px;
          display: inline-block;
        "
      >
        <a-button type="primary" @click="addLink()">添加友情链接</a-button>
      </div>
    </div>
    <div class="tree-table">
      <a-table :dataSource="linkData" :columns="columns">
        <template #headerCell="{ column }">
          <div style="text-align: center">
            {{ column.title }}
          </div>
        </template>

        <!--        空数据-->
        <template #emptyText>
          <a-empty v-if="linkData.length === 0" :image="simpleImage">
            <template #description>
              <span style="color: #c5c5c5"> 暂无数据 </span>
            </template>
            <a-button style="margin-top: 20px" type="primary" @click="addLink()"
              >添加友情链接</a-button
            >
          </a-empty>
        </template>

        <!--        有数据-->
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'sort'">
            <div>
              <a-input-number
                :min="0"
                :max="99"
                :controls="false"
                v-model:value="record.sort"
                @blur="sortChange(record.id, record.sort)"
              />
            </div>
          </template>
          <template v-if="column.key === 'id'">
            <div style="text-align: center">
              {{ record.id }}
            </div>
          </template>
          <template v-else-if="column.key === 'title'">
            <span>
              {{ record.title }}
            </span>
          </template>
          <template v-else-if="column.key === 'url'">
            <span>
              {{ record.url }}
            </span>
          </template>
          <template v-else-if="column.key === 'status'">
            <div style="text-align: center">
              <a-tag
                v-if="record.status === 0"
                type="default"
                @click="updateStatus(record.id, 1)"
              >
                隐藏
              </a-tag>
              <a-tag v-else color="success" @click="updateStatus(record.id, 0)">
                显示
              </a-tag>
            </div>
          </template>
          <template v-else-if="column.key === 'action'">
            <div style="text-align: center">
              <a-button
                type="link"
                style="display: inline-block; padding: 0 5px"
                @click="updateLink(record.id, record.title, record.url)"
                >编辑
              </a-button>
              <a-button
                type="link"
                danger
                style="display: inline-block; padding: 0 5px"
                @click="deleteItem(record.id)"
              >
                删除
              </a-button>
            </div>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  message,
  Table,
  Tag,
  Button,
  Input,
  InputNumber,
  Empty,
  Modal,
  Form,
  FormItem,
} from "ant-design-vue/es";
import set from "../../api/set";

export default {
  name: "Link",
  components: {
    ATag: Tag,
    AInputNumber: InputNumber,
    AEmpty: Empty,
    AModal: Modal,
    ATable: Table,
    AButton: Button,
    AInput: Input,
    AForm: Form,
    AFormItem: FormItem,
  },
  data() {
    return {
      addModalStatus: false,
      updateModalStatus: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      sortSwitch: false,
      pagination: {
        total: 0,
      },
      linkData: [],
      addData: { title: "", url: "" },
      updateData: { id: 0, title: "title", url: "" },
      columns: [
        {
          title: "排序",
          dataIndex: "sort",
          key: "sort",
          width: 80,
        },
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "名称",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "链接",
          dataIndex: "url",
          key: "url",
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
        },
      ],
    };
  },
  mounted() {
    this.getLink();
  },
  methods: {
    getLink() {
      let that = this;
      set
        .getLink()
        .then((res) => {
          if (res.code === 0) {
            that.linkData = res.data;
            console.log(that.linkData);
          } else {
            console.log(res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addLink() {
      this.addModalStatus = true;
    },
    updateLink(id, title, url) {
      this.updateData = {
        id: id,
        title: title,
        url: url,
      };
      this.updateModalStatus = true;
    },
    sortChange(id, sortData) {
      let data = {
        id: id,
        sort: sortData,
      };
      set
        .updateLink(data)
        .then((res) => {
          if (res.code === 0) {
            message.success("更新成功");
            this.getLink();
          } else {
            message.error("数据未更新或更新失败");
          }
        })
        .catch((e) => {
          let error = JSON.parse(JSON.stringify(e));
          message.error(
            "出现错误，请联系管理员！错误代码：" +
              error.status +
              ",错误信息：" +
              error.message
          );
        });
    },
    /**
     * 修改提交
     * @returns {boolean}
     */
    handleOk() {
      if (this.addData.title === "" || this.addData.title.length > 60) {
        message.info("标题请输入1-60个字符");
        return false;
      } else if (this.addData.url === "" || this.addData.url.length > 250) {
        message.info("网址请输入1-250个字符");
        return false;
      } else {
        console.log(this.addData);
        set
          .updateLink(this.addData)
          .then((res) => {
            if (res.code === 0) {
              message.success(res.msg);
              this.getLink();
              this.addModalStatus = false;
              this.addData = { title: "", url: "" };
            } else {
              message.error(
                "出现错误，请联系管理员！错误代码：" +
                  res.code +
                  ",错误信息：" +
                  res.message
              );
            }
          })
          .catch((e) => {
            let error = JSON.parse(JSON.stringify(e));
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                error.status +
                ",错误信息：" +
                error.message
            );
          });
      }
    },
    handleUpdateOk() {
      if (this.updateData.title === "" || this.updateData.title.length > 60) {
        message.info("标题请输入1-60个字符");
        return false;
      } else if (
        this.updateData.url === "" ||
        this.updateData.url.length > 250
      ) {
        message.info("网址请输入1-250个字符");
        return false;
      } else {
        console.log(this.updateData);
        set
          .updateLink(this.updateData)
          .then((res) => {
            if (res.code === 0) {
              message.success(res.msg);
              this.getLink();
              this.updateModalStatus = false;
              this.updateData = { id: 0, title: "", url: "" };
            } else {
              message.error(
                "出现错误，请联系管理员！错误代码：" +
                  res.code +
                  ",错误信息：" +
                  res.message
              );
            }
          })
          .catch((e) => {
            let error = JSON.parse(JSON.stringify(e));
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                error.status +
                ",错误信息：" +
                error.message
            );
          });
      }
    },
    updateStatus(id, status) {
      const that = this;
      let data = {
        id: id,
        status: status,
      };
      set
        .updateLink(data)
        .then((res) => {
          if (res.code === 0) {
            message.success("状态更新成功");
            that.getLink();
          } else {
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                res.code +
                ",错误信息：" +
                res.message
            );
          }
        })
        .catch((e) => {
          let error = JSON.parse(JSON.stringify(e));
          message.error(
            "出现错误，请联系管理员！错误代码：" +
              error.status +
              ",错误信息：" +
              error.message
          );
        });
    },
  },
  deleteItem(id) {
    const that = this;
    Modal.confirm({
      title: "确定要删除这条友情链接吗？",
      cancelText: "取消",
      okType: "danger",
      okText: "删除",
      onOk() {
        set
          .deleteLink(id)
          .then((res) => {
            if (res.code === 0) {
              message.success("删除成功");
              console.log("delete");
              that.getLink();
            } else {
              message.error(
                "出现错误，请联系管理员！错误代码：" +
                  res.code +
                  ",错误信息：" +
                  res.message
              );
            }
          })
          .catch((e) => {
            let error = JSON.parse(JSON.stringify(e));
            message.error(
              "出现错误，请联系管理员！错误代码：" +
                error.status +
                ",错误信息：" +
                error.message
            );
          });
      },
      onCancel() {
        message.info("吓死宝宝了，我以为你要删除了呢！～");
      },
    });
  },
};
</script>

<style scoped>
.tree-table {
  height: calc(100vh - 267px);
  padding: 20px;
  position: relative;
  background-color: #ffffff;
}

.icon {
  padding: 0 20px;
  cursor: move;
}
</style>
